/**
 * TO BE DEPRECATED
 * Consider using *-common.css or common.css instead, or one of the categorical files below:
 */
/**
 * CSS for laying out and displaying blocks and boxes
 */
:root {

    /* Consistent paddings */

    /* shadows */

    /* All z-index values should be listed here */

    /* widths */
}
/**
 * CSS for colors
 */
/* stylelint-disable color-no-hex */
:root {
    /* TIER ONE - DESCRIPTIVE NAMES */

    /* Default / Text color + Light background / Background color + Light text */        /* dark orange */          /* brand blue */           /* red */        /* purple */         /* dark green */          /* gray */            /* white */        /* yellow */

    /* Highlight / Text color + Dark background / Background color + Dark text */                                /* orange */            /* light red #f0425f */      /* light purple #ce51e1 */                                 /* bright green */                                  /* bright blue - brand color */                                /* light yellow */

    /* Alerts */

    /* Additional blues */         /* dark blue #003366 */  /* medium blue #0066cc */ /* light blue #c7e3ff */  /* light blue #e5f2ff */

    /* Additional reds */  /* light red #fbd0d7 */  /* dark red #8E0B21 */

    /* Additional greens */                                 /* dark green */ /* light green #e5fff1 */

    /* Additional purples */    /* extra light purple #f3d3f8 */       /* very light purple #f3d3f840 */

    /* Additional oranges */    /* light orange #ffe8cc */

    /* Grayscale Colors */  /* Near black */ /* Dark gray #1d2830 */  /* Formerly graphite */       /* medium gray */ /* #b1b2b4 */        /* #d8d9da */  /* Near white #f2f2f3 */

    /* TIER TWO - SEMANTIC NAMES */

    /* Product Colors */

    /* Component Colors */

    /* Partner/Refi Lender Colors */

    /* First Republic palette */    /* forest green */        /* gold hacienda */        /* tan gray concrete */      /* dark gray matterhorn */

    /* Visualization palette */

    /* Marketing Page Colors */ /* #1d2830; */ /* #005cb9; *//* #0f1519; */ /* #1d2830; */ /* #5a666e; *//* #8a8c8f; */ /* #b1b2b4; */ /* #d7d8d9; *//* #f2f2f3; */ /* #e5f2ff; */ /* #eddef0; */
}
/* this section is deprecated - prefer to use device-specific names if possible */
/* device based size names */
/* iPhone 5 width: 20em */
/* iPhone 6 width: */
/* iPhone 5 height: 35.5em */
/* iPhone 6 plus height: 46em */
/* iPad width: 48em */
/* iPad height: 64em */
.desktop {
    display: block;
}
.mobile {
    display: none;
}
@media (max-width: 30em) {
    .desktop {
        display: none;
    }

    .mobile {
        display: block;
    }
}
/**
 * CSS for text, titles, labels. word.
 */
/* Marketing Text Mixins */
/* basic setup for triangles in :before and :after pseudo-elements */
/* clears ul/ol defaults */
/* TODO Deprecated */
/* best pronounced with a texan drawl */
/* is this annoying yet? */
/* Marketing Button Mixins */
.flash-page-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 120;
}
.flash-page-wrapper .flash-container {
        position: fixed;
    }
.flash-message {
    overflow: hidden;
    padding: 8px;
    width: 100%;
}
.flash-message:before {
    content: '';
    display: table;
}
.flash-message:after {
    content: '';
    display: table;
    clear: both;
}
.flash-message.flash-enter {
        padding: 0 8px;
        max-height: 0;
    }
.flash-message.flash-enter.flash-enter-active {
        padding: 8px;
        max-height: 100px;
        -webkit-transition: max-height 0.4s ease-in, padding 0.4s ease-in;
        -o-transition: max-height 0.4s ease-in, padding 0.4s ease-in;
        transition: max-height 0.4s ease-in, padding 0.4s ease-in;
    }
.flash-message.flash-exit {
        padding: 8px;
        max-height: 100px;
    }
.flash-message.flash-exit.flash-exit-active {
        padding: 0 8px;
        max-height: 0;
        -webkit-transition: max-height 0.4s ease-out, padding 0.4s ease-out;
        -o-transition: max-height 0.4s ease-out, padding 0.4s ease-out;
        transition: max-height 0.4s ease-out, padding 0.4s ease-out;
    }
.flash-message .close-button {
        float: right;
        padding: 0;
        margin: 0;
        background-color: transparent;
    }
.flash-message a {
        margin-left: 8px;
        text-decoration: underline;
        color: #005cb9;
        cursor: pointer;
    }
.flash-message a:hover {
            color: blue;
        }
.flash-container {
    width: 100%;
}
.flash-container .flash-message {
        font-size: 1.125rem;
        line-height: 1.5;
        font-weight: 400;
        font-family: 'Montserrat', Helvetica, sans-serif;

        font-weight: 600;
        text-align: center;
    }
.flash-container .flash-message.success {
            color: white;
            background-color: #00843d;
        }
.flash-container .flash-message.warning {
            color: white;
            background-color: #b36200;
        }
.flash-container .flash-message.error {
            color: white;
            background-color: #c8102e;
        }
.message-container .flash-message {
        margin-bottom: 16px;
        font-weight: 600;
        border: 1px solid rgb(177, 178, 180);
    }
.message-container .flash-message.success {
            color: #00843d;
            border-color: #00843d;
        }
.message-container .flash-message.warning {
            color: #e58c23;
            border-color: #e58c23;
        }
.message-container .flash-message.error {
            color: #c8102e;
            border-color: #c8102e;
        }
.message-container .flash-message .icon {
            padding-right: 4px;
        }
