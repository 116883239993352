/**
 * TO BE DEPRECATED
 * Consider using *-common.css or common.css instead, or one of the categorical files below:
 */
/**
 * CSS for laying out and displaying blocks and boxes
 */
:root {

    /* Consistent paddings */

    /* shadows */

    /* All z-index values should be listed here */

    /* widths */
}
/**
 * CSS for colors
 */
/* stylelint-disable color-no-hex */
:root {
    /* TIER ONE - DESCRIPTIVE NAMES */

    /* Default / Text color + Light background / Background color + Light text */        /* dark orange */          /* brand blue */           /* red */        /* purple */         /* dark green */          /* gray */            /* white */        /* yellow */

    /* Highlight / Text color + Dark background / Background color + Dark text */                                /* orange */            /* light red #f0425f */      /* light purple #ce51e1 */                                 /* bright green */                                  /* bright blue - brand color */                                /* light yellow */

    /* Alerts */

    /* Additional blues */         /* dark blue #003366 */  /* medium blue #0066cc */ /* light blue #c7e3ff */  /* light blue #e5f2ff */

    /* Additional reds */  /* light red #fbd0d7 */  /* dark red #8E0B21 */

    /* Additional greens */                                 /* dark green */ /* light green #e5fff1 */

    /* Additional purples */    /* extra light purple #f3d3f8 */       /* very light purple #f3d3f840 */

    /* Additional oranges */    /* light orange #ffe8cc */

    /* Grayscale Colors */  /* Near black */ /* Dark gray #1d2830 */  /* Formerly graphite */       /* medium gray */ /* #b1b2b4 */        /* #d8d9da */  /* Near white #f2f2f3 */

    /* TIER TWO - SEMANTIC NAMES */

    /* Product Colors */

    /* Component Colors */

    /* Partner/Refi Lender Colors */

    /* First Republic palette */    /* forest green */        /* gold hacienda */        /* tan gray concrete */      /* dark gray matterhorn */

    /* Visualization palette */

    /* Marketing Page Colors */ /* #1d2830; */ /* #005cb9; *//* #0f1519; */ /* #1d2830; */ /* #5a666e; *//* #8a8c8f; */ /* #b1b2b4; */ /* #d7d8d9; *//* #f2f2f3; */ /* #e5f2ff; */ /* #eddef0; */
}
/* this section is deprecated - prefer to use device-specific names if possible */
/* device based size names */
/* iPhone 5 width: 20em */
/* iPhone 6 width: */
/* iPhone 5 height: 35.5em */
/* iPhone 6 plus height: 46em */
/* iPad width: 48em */
/* iPad height: 64em */
.desktop {
    display: block;
}
.mobile {
    display: none;
}
@media (max-width: 30em) {
    .desktop {
        display: none;
    }

    .mobile {
        display: block;
    }
}
/**
 * CSS for text, titles, labels. word.
 */
/* Marketing Text Mixins */
/* basic setup for triangles in :before and :after pseudo-elements */
/* clears ul/ol defaults */
/* TODO Deprecated */
/* best pronounced with a texan drawl */
/* is this annoying yet? */
/* Marketing Button Mixins */
.session-expiring-modal .session-expiration-info p {
            margin-top: 16px;
        }
.session-expiring-modal .session-expiration-modal-buttons {
        border-top: 1px solid darkgray;
        margin-top: 16px;
        text-align: center;
    }
.session-expiring-modal .session-expiration-modal-buttons button {
            margin-top: 16px;
        }
.session-expiring-modal .session-expiration-modal-buttons .session-logout {
            margin-left: 65px;
        }
.session-expiring-modal .session-expiration-modal-buttons .session-extend {
            display: inline-block;
            text-decoration: none;
            padding: 8px 16px;
            border-radius: 24px;
            cursor: pointer;
            -webkit-transition: all 0.2s ease-in;
            -o-transition: all 0.2s ease-in;
            transition: all 0.2s ease-in;
            text-align: center;
            display: inline-block;
            text-decoration: none;
            padding: 8px 16px;
            border-radius: 24px;
            cursor: pointer;
            transition: all 0.2s ease-in;
            text-align: center;
            border: 2px solid #005cb9;
            color: white;
            background-color: #005cb9;
        }
@media (max-width: 40em) {
.session-expiring-modal .session-expiration-modal-buttons .session-extend {
        height: 50px;
        margin: 8px 0;
        padding: 16px 16px 8px 16px;
        text-align: center;
        width: 100%;
        }
    }
@media (max-width: 40em) {
.session-expiring-modal .session-expiration-modal-buttons .session-extend {
        height: 50px;
        margin: 8px 0;
        padding: 16px 16px 8px 16px;
        text-align: center;
        width: 100%;
        }
    }
.session-expiring-modal .session-expiration-modal-buttons .session-extend:hover {
        background-color: rgb(0, 76, 153);
        border-color: rgb(0, 76, 153);
    }
.session-expiring-modal .session-expiration-modal-buttons .session-extend:disabled {
        border-color: #8a8c8f;
        background-color: #8a8c8f;
        color: white;
        cursor: default;
    }
@media (max-width: 30em) {
            .session-expiring-modal .session-expiration-modal-buttons .session-logout {
                margin-left: 0;
            }
}
