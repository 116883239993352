/**
 * TO BE DEPRECATED
 * Consider using *-common.css or common.css instead, or one of the categorical files below:
 */
/**
 * CSS for laying out and displaying blocks and boxes
 */
:root {

    /* Consistent paddings */

    /* shadows */

    /* All z-index values should be listed here */

    /* widths */
}
/**
 * CSS for colors
 */
/* stylelint-disable color-no-hex */
:root {
    /* TIER ONE - DESCRIPTIVE NAMES */

    /* Default / Text color + Light background / Background color + Light text */        /* dark orange */          /* brand blue */           /* red */        /* purple */         /* dark green */          /* gray */            /* white */        /* yellow */

    /* Highlight / Text color + Dark background / Background color + Dark text */                                /* orange */            /* light red #f0425f */      /* light purple #ce51e1 */                                 /* bright green */                                  /* bright blue - brand color */                                /* light yellow */

    /* Alerts */

    /* Additional blues */         /* dark blue #003366 */  /* medium blue #0066cc */ /* light blue #c7e3ff */  /* light blue #e5f2ff */

    /* Additional reds */  /* light red #fbd0d7 */  /* dark red #8E0B21 */

    /* Additional greens */                                 /* dark green */ /* light green #e5fff1 */

    /* Additional purples */    /* extra light purple #f3d3f8 */       /* very light purple #f3d3f840 */

    /* Additional oranges */    /* light orange #ffe8cc */

    /* Grayscale Colors */  /* Near black */ /* Dark gray #1d2830 */  /* Formerly graphite */       /* medium gray */ /* #b1b2b4 */        /* #d8d9da */  /* Near white #f2f2f3 */

    /* TIER TWO - SEMANTIC NAMES */

    /* Product Colors */

    /* Component Colors */

    /* Partner/Refi Lender Colors */

    /* First Republic palette */    /* forest green */        /* gold hacienda */        /* tan gray concrete */      /* dark gray matterhorn */

    /* Visualization palette */

    /* Marketing Page Colors */ /* #1d2830; */ /* #005cb9; *//* #0f1519; */ /* #1d2830; */ /* #5a666e; *//* #8a8c8f; */ /* #b1b2b4; */ /* #d7d8d9; *//* #f2f2f3; */ /* #e5f2ff; */ /* #eddef0; */
}
/* this section is deprecated - prefer to use device-specific names if possible */
/* device based size names */
/* iPhone 5 width: 20em */
/* iPhone 6 width: */
/* iPhone 5 height: 35.5em */
/* iPhone 6 plus height: 46em */
/* iPad width: 48em */
/* iPad height: 64em */
.desktop {
    display: block;
}
.mobile {
    display: none;
}
@media (max-width: 30em) {
    .desktop {
        display: none;
    }

    .mobile {
        display: block;
    }
}
/**
 * CSS for text, titles, labels. word.
 */
/* Marketing Text Mixins */
/* basic setup for triangles in :before and :after pseudo-elements */
/* clears ul/ol defaults */
/* TODO Deprecated */
/* best pronounced with a texan drawl */
/* is this annoying yet? */
/* Marketing Button Mixins */
.modal-enter {
    opacity: 0.01;
}
.modal-enter.modal-enter-active {
    opacity: 1;
    -webkit-transition: opacity 300ms ease-in;
    -o-transition: opacity 300ms ease-in;
    transition: opacity 300ms ease-in;
}
.modal-exit {
    opacity: 1;
}
.modal-exit.modal-exit-active {
    opacity: 0.01;
    -webkit-transition: opacity 300ms ease-in;
    -o-transition: opacity 300ms ease-in;
    transition: opacity 300ms ease-in;
}
.modal-appear {
    opacity: 0.01;
}
.modal-appear.modal-appear-active {
    opacity: 1;
    -webkit-transition: opacity 300ms ease-in;
    -o-transition: opacity 300ms ease-in;
    transition: opacity 300ms ease-in;
}
.modal-overlay {
    background-color: rgba(0, 0, 0, 0.5);
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 81;
    max-width: 100vw;

    /* goes above most everything else */
    overflow-y: auto;
}
.modal-overlay .ReactCrop--crop-selection {
        display: none;
    }
.modal-dialog {
    border-radius: 4px;
    position: relative;
    background: white;
    outline: none;
    margin: 32px auto 16px;
    padding: 0;
    max-width: 95vw;
}
@media (min-width: 45em) {
.modal-dialog {
        width: 740px
}
    }
.modal-dialog.message-box {
        width: 300px;
    }
.modal-header {
    font-size: 1.125rem;
    line-height: 1.5;
    font-weight: 400;
    font-family: 'Montserrat', Helvetica, sans-serif;

    border-radius: 4px 4px 0 0;
    font-weight: 500;
    border-bottom: 1px solid rgb(216, 217, 218);
    color: rgb(29, 40, 48);
    padding: 16px;
    background-color: white;
}
.modal-content {
    padding: 32px;
    overflow: auto;
    max-height: 100%;
    background: white;
    border-radius: 0 0 4px 4px;
}
.modal-radio-container {
    margin: 16px 0;
    line-height: 24px;
}
.modal-footer {
    border-radius: 0 0 4px 4px;
    background-color: rgba(0,92,185, 0.1);
    padding: 16px;
}
.modal-footer .modal-footer-close-button {
        display: inline-block;
        text-decoration: none;
        padding: 8px 16px;
        border-radius: 24px;
        cursor: pointer;
        -webkit-transition: all 0.2s ease-in;
        -o-transition: all 0.2s ease-in;
        transition: all 0.2s ease-in;
        text-align: center;

        display: block;
        margin-left: auto;
    }
@media (max-width: 40em) {
.modal-footer .modal-footer-close-button {
        height: 50px;
        margin: 8px 0;
        padding: 16px 16px 8px 16px;
        text-align: center;
        width: 100%;
    }
    }
.modal-close-button {
    float: right;
    display: block;
    border: none;
    background: transparent;
    padding: 0;
    font-size: 1.25rem;
    line-height: 1.25;
    color: rgb(29, 40, 48);
    cursor: pointer;
    width: 32px;
    height: 32px;
    margin: 0;
}
@media (max-width: 50em) {
    .modal-content {
        padding: 16px;
    }
}
