/* this section is deprecated - prefer to use device-specific names if possible */
/* device based size names */
/* iPhone 5 width: 20em */
/* iPhone 6 width: */
/* iPhone 5 height: 35.5em */
/* iPhone 6 plus height: 46em */
/* iPad width: 48em */
/* iPad height: 64em */
.desktop {
    display: block;
}
.mobile {
    display: none;
}
@media (max-width: 30em) {
    .desktop {
        display: none;
    }

    .mobile {
        display: block;
    }
}
/**
 * CSS for colors
 */
/* stylelint-disable color-no-hex */
:root {
    /* TIER ONE - DESCRIPTIVE NAMES */

    /* Default / Text color + Light background / Background color + Light text */        /* dark orange */          /* brand blue */           /* red */        /* purple */         /* dark green */          /* gray */            /* white */        /* yellow */

    /* Highlight / Text color + Dark background / Background color + Dark text */                                /* orange */            /* light red #f0425f */      /* light purple #ce51e1 */                                 /* bright green */                                  /* bright blue - brand color */                                /* light yellow */

    /* Alerts */

    /* Additional blues */         /* dark blue #003366 */  /* medium blue #0066cc */ /* light blue #c7e3ff */  /* light blue #e5f2ff */

    /* Additional reds */  /* light red #fbd0d7 */  /* dark red #8E0B21 */

    /* Additional greens */                                 /* dark green */ /* light green #e5fff1 */

    /* Additional purples */    /* extra light purple #f3d3f8 */       /* very light purple #f3d3f840 */

    /* Additional oranges */    /* light orange #ffe8cc */

    /* Grayscale Colors */  /* Near black */ /* Dark gray #1d2830 */  /* Formerly graphite */       /* medium gray */ /* #b1b2b4 */        /* #d8d9da */  /* Near white #f2f2f3 */

    /* TIER TWO - SEMANTIC NAMES */

    /* Product Colors */

    /* Component Colors */

    /* Partner/Refi Lender Colors */

    /* First Republic palette */    /* forest green */        /* gold hacienda */        /* tan gray concrete */      /* dark gray matterhorn */

    /* Visualization palette */

    /* Marketing Page Colors */ /* #1d2830; */ /* #005cb9; *//* #0f1519; */ /* #1d2830; */ /* #5a666e; *//* #8a8c8f; */ /* #b1b2b4; */ /* #d7d8d9; *//* #f2f2f3; */ /* #e5f2ff; */ /* #eddef0; */
}
/**
 * CSS for text, titles, labels. word.
 */
/* Marketing Text Mixins */
/**
 * CSS for laying out and displaying blocks and boxes
 */
:root {

    /* Consistent paddings */

    /* shadows */

    /* All z-index values should be listed here */

    /* widths */
}
/* basic setup for triangles in :before and :after pseudo-elements */
/* clears ul/ol defaults */
/* TODO Deprecated */
/* best pronounced with a texan drawl */
/* is this annoying yet? */
/* Marketing Button Mixins */
.site-footer {
    color: white;
    z-index: 40;
    background: url("../../assets/FooterDesktop.png") no-repeat;
    background-size: cover;
}
.site-footer .content-wrapper {
        max-width: 1180px;
        margin: auto;
    }
.site-footer .site-footer-content {
        display: -webkit-box;
        display: -webkit-flex;
        display: -moz-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: justify;
        -webkit-justify-content: space-between;
           -moz-box-pack: justify;
            -ms-flex-pack: justify;
                justify-content: space-between;
        padding: 32px 32px 0;
    }
.site-footer .site-footer-content .social-content {
            width: 296px;
            width: calc(99.9% * 1/3 - (16px - 16px * 1/3));
            -webkit-box-align: center;
            -webkit-align-items: center;
               -moz-box-align: center;
                -ms-flex-align: center;
                    align-items: center;
        }
.site-footer .site-footer-content .social-content:nth-child(1n) {
            float: left;
            margin-right: 16px;
            clear: none;
        }
.site-footer .site-footer-content .social-content:last-child {
            margin-right: 0;
        }
.site-footer .site-footer-content .social-content:nth-child(0n) {
            float: right;
        }
.site-footer .site-footer-content .social-content .logo {
                width: 100%;
                height: 56px;
            }
.site-footer .site-footer-content .social-content .logo .footer-logo {
                    width: 100%;
                    height: 56px;
                    background: url("../../../gradifui/assets/Gradifi-Logo-Horiz-White.svg") left center no-repeat;
                    background-size: contain;
                }
.site-footer .site-footer-content .social-content .linkedin-logo {
                right: 2px;
                width: 96px;
                height: 32px;
                background: url("../../../gradifui/assets/linkedin_logo.svg") center no-repeat;
                background-size: contain;
            }
.site-footer .site-footer-content .link-content {
            display: -webkit-box;
            display: -webkit-flex;
            display: -moz-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-orient: horizontal;
            -webkit-box-direction: normal;
            -webkit-flex-direction: row;
               -moz-box-orient: horizontal;
               -moz-box-direction: normal;
                -ms-flex-direction: row;
                    flex-direction: row;
            width: calc(99.9% * 2/3 - (16px - 16px * 2/3));
            -webkit-box-pack: justify;
            -webkit-justify-content: space-between;
               -moz-box-pack: justify;
                -ms-flex-pack: justify;
                    justify-content: space-between;
            padding-top: 8px;
        }
.site-footer .site-footer-content .link-content:nth-child(1n) {
            float: left;
            margin-right: 16px;
            clear: none;
        }
.site-footer .site-footer-content .link-content:last-child {
            margin-right: 0;
        }
.site-footer .site-footer-content .link-content:nth-child(0n) {
            float: right;
        }
.site-footer .site-footer-content .link-content .static-columns {
                display: -webkit-box;
                display: -webkit-flex;
                display: -moz-box;
                display: -ms-flexbox;
                display: flex;
                -webkit-box-orient: horizontal;
                -webkit-box-direction: normal;
                -webkit-flex-direction: row;
                   -moz-box-orient: horizontal;
                   -moz-box-direction: normal;
                    -ms-flex-direction: row;
                        flex-direction: row;
                -webkit-box-flex: 0;
                -webkit-flex-grow: 0;
                   -moz-box-flex: 0;
                    -ms-flex-positive: 0;
                        flex-grow: 0;
                -webkit-flex-shrink: 0;
                    -ms-flex-negative: 0;
                        flex-shrink: 0;
                -webkit-flex-basis: calc(99.9% * 3/5 - (16px - 16px * 3/5));
                    -ms-flex-preferred-size: calc(99.9% * 3/5 - (16px - 16px * 3/5));
                        flex-basis: calc(99.9% * 3/5 - (16px - 16px * 3/5));
                max-width: calc(99.9% * 3/5 - (16px - 16px * 3/5));
                width: calc(99.9% * 3/5 - (16px - 16px * 3/5));
                -webkit-justify-content: space-around;
                    -ms-flex-pack: distribute;
                        justify-content: space-around;
            }
.site-footer .site-footer-content .link-content .static-columns:nth-child(1n) {
                margin-right: 16px;
                margin-left: 0;
            }
.site-footer .site-footer-content .link-content .static-columns:last-child {
                margin-right: 0;
            }
.site-footer .site-footer-content .link-content .static-columns:nth-child(5n) {
                margin-right: 0;
                margin-left: auto;
            }
/* No member support or demo column on employer-portal */
.site-footer .site-footer-content .link-content .employer-portal {
                -webkit-box-flex: 0;
                -webkit-flex-grow: 0;
                   -moz-box-flex: 0;
                    -ms-flex-positive: 0;
                        flex-grow: 0;
                -webkit-flex-shrink: 0;
                    -ms-flex-negative: 0;
                        flex-shrink: 0;
                -webkit-flex-basis: calc(99.9% * 1/1 - (16px - 16px * 1/1));
                    -ms-flex-preferred-size: calc(99.9% * 1/1 - (16px - 16px * 1/1));
                        flex-basis: calc(99.9% * 1/1 - (16px - 16px * 1/1));
                max-width: calc(99.9% * 1/1 - (16px - 16px * 1/1));
                width: calc(99.9% * 1/1 - (16px - 16px * 1/1));
            }
.site-footer .site-footer-content .link-content .employer-portal:nth-child(1n) {
                margin-right: 16px;
                margin-left: 0;
            }
.site-footer .site-footer-content .link-content .employer-portal:last-child {
                margin-right: 0;
            }
.site-footer .site-footer-content .link-content .employer-portal:nth-child(1n) {
                margin-right: 0;
                margin-left: auto;
            }
.site-footer .site-footer-content .link-content .about-gradifi {
                white-space: nowrap;
            }
.site-footer .site-footer-content .link-content .member-support-column,
            .site-footer .site-footer-content .link-content .demo-column {
                -webkit-box-flex: 0;
                -webkit-flex-grow: 0;
                   -moz-box-flex: 0;
                    -ms-flex-positive: 0;
                        flex-grow: 0;
                -webkit-flex-shrink: 0;
                    -ms-flex-negative: 0;
                        flex-shrink: 0;
                -webkit-flex-basis: calc(99.9% * 2/5 - (16px - 16px * 2/5));
                    -ms-flex-preferred-size: calc(99.9% * 2/5 - (16px - 16px * 2/5));
                        flex-basis: calc(99.9% * 2/5 - (16px - 16px * 2/5));
                max-width: calc(99.9% * 2/5 - (16px - 16px * 2/5));
                width: calc(99.9% * 2/5 - (16px - 16px * 2/5));
                max-width: 240px;
            }
.site-footer .site-footer-content .link-content .member-support-column:nth-child(1n),
            .site-footer .site-footer-content .link-content .demo-column:nth-child(1n) {
                margin-right: 16px;
                margin-left: 0;
            }
.site-footer .site-footer-content .link-content .member-support-column:last-child,
            .site-footer .site-footer-content .link-content .demo-column:last-child {
                margin-right: 0;
            }
.site-footer .site-footer-content .link-content .member-support-column:nth-child(5n),
            .site-footer .site-footer-content .link-content .demo-column:nth-child(5n) {
                margin-right: 0;
                margin-left: auto;
            }
.site-footer .site-footer-content .link-content ul {
                list-style: none;
                padding: 0;
                margin: 0;
            }
.site-footer .site-footer-content .link-content ul li {
                    padding-bottom: 24px;
                    font-size: 0.75rem;
                }
.site-footer .site-footer-content .link-content ul li a {
                        color: white;
                        text-decoration: none;
                    }
.site-footer .site-footer-content .link-content ul li a:hover {
                            color: #00a0d4;
                        }
.site-footer .site-footer-content .link-content ul .member-support-email {
                    word-break: break-word;
                }
.site-footer .site-footer-content .demo-login-content ul li {
            display: -webkit-box;
            display: -webkit-flex;
            display: -moz-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-orient: vertical;
            -webkit-box-direction: normal;
            -webkit-flex-direction: column;
               -moz-box-orient: vertical;
               -moz-box-direction: normal;
                -ms-flex-direction: column;
                    flex-direction: column;
            -webkit-box-align: center;
            -webkit-align-items: center;
               -moz-box-align: center;
                -ms-flex-align: center;
                    align-items: center;
            text-align: center;
        }
.site-footer .site-footer-content .demo-login-content ul li a {
                text-decoration: none;
            }
.site-footer .site-footer-content .demo-login-content ul li a.btn-demo {
                font-size: 0.625rem;
                font-weight: 700;
                text-decoration: none;
                margin-top: 0;
                color: black;
            }
.site-footer .privacy-terms-row,
    .site-footer .cpra-button {
        border-bottom: 1px solid #5a666e;
        font-size: 0.75rem;
        padding: 8px 0;
    }
.site-footer .cpra-button {
        width: auto;
    }
.site-footer .privacy-terms-row {
        display: -webkit-box;
        display: -webkit-flex;
        display: -moz-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -webkit-flex-direction: row;
           -moz-box-orient: horizontal;
           -moz-box-direction: normal;
            -ms-flex-direction: row;
                flex-direction: row;
        -webkit-box-pack: center;
        -webkit-justify-content: center;
           -moz-box-pack: center;
            -ms-flex-pack: center;
                justify-content: center;
    }
.site-footer .privacy-terms-row li {
            display: inline-block;
            margin-right: 24px;
        }
.site-footer .privacy-terms-row a {
            text-decoration: none;
        }
.site-footer .privacy-terms-row .privacy-logged-in {
            display: -webkit-box;
            display: -webkit-flex;
            display: -moz-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-orient: horizontal;
            -webkit-box-direction: normal;
            -webkit-flex-direction: row;
               -moz-box-orient: horizontal;
               -moz-box-direction: normal;
                -ms-flex-direction: row;
                    flex-direction: row;
            margin-top: 8px;
            width: 100%;
            -webkit-box-pack: center;
            -webkit-justify-content: center;
               -moz-box-pack: center;
                -ms-flex-pack: center;
                    justify-content: center;
        }
.site-footer .privacy-terms-row .privacy-logged-in .privacy-section,
            .site-footer .privacy-terms-row .privacy-logged-in .terms-section {
                display: -webkit-box;
                display: -webkit-flex;
                display: -moz-box;
                display: -ms-flexbox;
                display: flex;
                -webkit-box-orient: horizontal;
                -webkit-box-direction: normal;
                -webkit-flex-direction: row;
                   -moz-box-orient: horizontal;
                   -moz-box-direction: normal;
                    -ms-flex-direction: row;
                        flex-direction: row;
                -webkit-box-pack: center;
                -webkit-justify-content: center;
                   -moz-box-pack: center;
                    -ms-flex-pack: center;
                        justify-content: center;
                margin-bottom: 16px;
            }
.site-footer .privacy-terms-row .privacy-logged-in a,
            .site-footer .privacy-terms-row .privacy-logged-in #cpra-button {
                margin-right: 24px;
            }
.site-footer .disclosure-row {
        display: -webkit-box;
        display: -webkit-flex;
        display: -moz-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -webkit-flex-direction: row;
           -moz-box-orient: horizontal;
           -moz-box-direction: normal;
            -ms-flex-direction: row;
                flex-direction: row;
        font-size: 0.625rem;
    }
.site-footer .disclosure-row .disclosure-items {
            width: calc(99.9% * 1/2);
            padding: 16px;
        }
.site-footer .disclosure-row .disclosure-items:nth-child(1n) {
            float: left;
            margin-right: 0;
            clear: none;
        }
.site-footer .disclosure-row .disclosure-items:last-child {
            margin-right: 0;
        }
.site-footer .disclosure-row .disclosure-items:nth-child(0n) {
            float: right;
        }
@media (max-width: 60em) {
    .site-footer .site-footer-content {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-direction: column;
           -moz-box-orient: vertical;
           -moz-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column;
    }

        .site-footer .site-footer-content .social-content {
            margin: 0 auto 32px !important;
            width: calc(99.9% * 1/2 - (16px - 16px * 1/2));
        }

        .site-footer .site-footer-content .social-content:nth-child(1n) {
            float: left;
            margin-right: 16px;
            clear: none;
        }

        .site-footer .site-footer-content .social-content:last-child {
            margin-right: 0;
        }

        .site-footer .site-footer-content .social-content:nth-child(0n) {
            float: right;
        }

        .site-footer .site-footer-content .link-content {
            margin: 0 auto !important;
            -webkit-box-orient: horizontal;
            -webkit-box-direction: normal;
            -webkit-flex-direction: row;
               -moz-box-orient: horizontal;
               -moz-box-direction: normal;
                -ms-flex-direction: row;
                    flex-direction: row;
            -webkit-justify-content: space-around;
                -ms-flex-pack: distribute;
                    justify-content: space-around;
        }

            .site-footer .site-footer-content .link-content .static-columns {
                -webkit-box-flex: 0;
                -webkit-flex-grow: 0;
                   -moz-box-flex: 0;
                    -ms-flex-positive: 0;
                        flex-grow: 0;
                -webkit-flex-shrink: 0;
                    -ms-flex-negative: 0;
                        flex-shrink: 0;
                -webkit-flex-basis: calc(99.9% * 2/3 - (16px - 16px * 2/3));
                    -ms-flex-preferred-size: calc(99.9% * 2/3 - (16px - 16px * 2/3));
                        flex-basis: calc(99.9% * 2/3 - (16px - 16px * 2/3));
                max-width: calc(99.9% * 2/3 - (16px - 16px * 2/3));
                width: calc(99.9% * 2/3 - (16px - 16px * 2/3));
            }

            .site-footer .site-footer-content .link-content .static-columns:nth-child(1n) {
                margin-right: 16px;
                margin-left: 0;
            }

            .site-footer .site-footer-content .link-content .static-columns:last-child {
                margin-right: 0;
            }

            .site-footer .site-footer-content .link-content .static-columns:nth-child(3n) {
                margin-right: 0;
                margin-left: auto;
            }

            .site-footer .site-footer-content .link-content .member-support-column,
            .site-footer .site-footer-content .link-content .demo-column {
                -webkit-box-flex: 0;
                -webkit-flex-grow: 0;
                   -moz-box-flex: 0;
                    -ms-flex-positive: 0;
                        flex-grow: 0;
                -webkit-flex-shrink: 0;
                    -ms-flex-negative: 0;
                        flex-shrink: 0;
                -webkit-flex-basis: calc(99.9% * 1/3 - (16px - 16px * 1/3));
                    -ms-flex-preferred-size: calc(99.9% * 1/3 - (16px - 16px * 1/3));
                        flex-basis: calc(99.9% * 1/3 - (16px - 16px * 1/3));
                max-width: calc(99.9% * 1/3 - (16px - 16px * 1/3));
                width: calc(99.9% * 1/3 - (16px - 16px * 1/3));
            }

            .site-footer .site-footer-content .link-content .member-support-column:nth-child(1n),
            .site-footer .site-footer-content .link-content .demo-column:nth-child(1n) {
                margin-right: 16px;
                margin-left: 0;
            }

            .site-footer .site-footer-content .link-content .member-support-column:last-child,
            .site-footer .site-footer-content .link-content .demo-column:last-child {
                margin-right: 0;
            }

            .site-footer .site-footer-content .link-content .member-support-column:nth-child(3n),
            .site-footer .site-footer-content .link-content .demo-column:nth-child(3n) {
                margin-right: 0;
                margin-left: auto;
            }

                .site-footer .site-footer-content .link-content .member-support-column .member-support-phone,
                .site-footer .site-footer-content .link-content .member-support-column .member-support-email,
                .site-footer .site-footer-content .link-content .demo-column .member-support-phone,
                .site-footer .site-footer-content .link-content .demo-column .member-support-email {
                    white-space: nowrap;
                }
}
@media (max-width: 50em) {
    .site-footer {
        background: url("../../assets/FooterTablet.png") no-repeat cover;
    }
            .site-footer .privacy-terms-row ul {
                padding-left: 16px;
                text-align: center;
            }

            .site-footer .privacy-terms-row .privacy-logged-in {
                -webkit-box-orient: vertical;
                -webkit-box-direction: normal;
                -webkit-flex-direction: column;
                   -moz-box-orient: vertical;
                   -moz-box-direction: normal;
                    -ms-flex-direction: column;
                        flex-direction: column;
            }

                .site-footer .privacy-terms-row .privacy-logged-in .privacy-section,
                .site-footer .privacy-terms-row .privacy-logged-in .terms-section {
                    -webkit-box-pack: left;
                    -webkit-justify-content: left;
                       -moz-box-pack: left;
                        -ms-flex-pack: left;
                            justify-content: left;
                    margin-left: 25%;
                }

                    .site-footer .privacy-terms-row .privacy-logged-in .privacy-section *, .site-footer .privacy-terms-row .privacy-logged-in .terms-section * {
                        width: 180px;
                        margin-right: 0;
                        text-align: left;
                    }
}
@media (max-width: 40em) {
    .site-footer {
        background: url("../../assets/FooterMobile.png") no-repeat;
    }

        .site-footer .site-footer-content {
            -webkit-box-orient: vertical;
            -webkit-box-direction: normal;
            -webkit-flex-direction: column;
               -moz-box-orient: vertical;
               -moz-box-direction: normal;
                -ms-flex-direction: column;
                    flex-direction: column;
            -webkit-box-align: center;
            -webkit-align-items: center;
               -moz-box-align: center;
                -ms-flex-align: center;
                    align-items: center;
            -webkit-box-flex: 1;
            -webkit-flex-grow: 1;
               -moz-box-flex: 1;
                -ms-flex-positive: 1;
                    flex-grow: 1;
            margin-bottom: 16px;
            padding: 16px 16px 0;
        }

            .site-footer .site-footer-content .social-content {
                width: 300px;
            }

            .site-footer .site-footer-content .link-content {
                max-width: 336px;
                width: 336px;
                min-height: 200px;
                -webkit-box-orient: vertical;
                -webkit-box-direction: normal;
                -webkit-flex-direction: column;
                   -moz-box-orient: vertical;
                   -moz-box-direction: normal;
                    -ms-flex-direction: column;
                        flex-direction: column;
            }

                .site-footer .site-footer-content .link-content .static-columns {
                    margin-left: 16px;
                    -webkit-box-flex: 0;
                    -webkit-flex-grow: 0;
                       -moz-box-flex: 0;
                        -ms-flex-positive: 0;
                            flex-grow: 0;
                    -webkit-flex-shrink: 0;
                        -ms-flex-negative: 0;
                            flex-shrink: 0;
                    -webkit-flex-basis: calc(99.9% * 1/1 - (16px - 16px * 1/1));
                        -ms-flex-preferred-size: calc(99.9% * 1/1 - (16px - 16px * 1/1));
                            flex-basis: calc(99.9% * 1/1 - (16px - 16px * 1/1));
                    max-width: calc(99.9% * 1/1 - (16px - 16px * 1/1));
                    width: calc(99.9% * 1/1 - (16px - 16px * 1/1));
                    -webkit-box-pack: justify;
                    -webkit-justify-content: space-between;
                       -moz-box-pack: justify;
                        -ms-flex-pack: justify;
                            justify-content: space-between;
                }

                .site-footer .site-footer-content .link-content .static-columns:nth-child(1n) {
                    margin-right: 16px;
                    margin-left: 0;
                }

                .site-footer .site-footer-content .link-content .static-columns:last-child {
                    margin-right: 0;
                }

                .site-footer .site-footer-content .link-content .static-columns:nth-child(1n) {
                    margin-right: 0;
                    margin-left: auto;
                }

                .site-footer .site-footer-content .link-content .member-support-column,
                .site-footer .site-footer-content .link-content .demo-column {
                    -webkit-box-flex: 0;
                    -webkit-flex-grow: 0;
                       -moz-box-flex: 0;
                        -ms-flex-positive: 0;
                            flex-grow: 0;
                    -webkit-flex-shrink: 0;
                        -ms-flex-negative: 0;
                            flex-shrink: 0;
                    -webkit-flex-basis: calc(99.9% * 1/1 - (16px - 16px * 1/1));
                        -ms-flex-preferred-size: calc(99.9% * 1/1 - (16px - 16px * 1/1));
                            flex-basis: calc(99.9% * 1/1 - (16px - 16px * 1/1));
                    max-width: calc(99.9% * 1/1 - (16px - 16px * 1/1));
                    width: calc(99.9% * 1/1 - (16px - 16px * 1/1));
                    margin: 16px auto 0;
                }

                .site-footer .site-footer-content .link-content .member-support-column:nth-child(1n),
                .site-footer .site-footer-content .link-content .demo-column:nth-child(1n) {
                    margin-right: 16px;
                    margin-left: 0;
                }

                .site-footer .site-footer-content .link-content .member-support-column:last-child,
                .site-footer .site-footer-content .link-content .demo-column:last-child {
                    margin-right: 0;
                }

                .site-footer .site-footer-content .link-content .member-support-column:nth-child(1n),
                .site-footer .site-footer-content .link-content .demo-column:nth-child(1n) {
                    margin-right: 0;
                    margin-left: auto;
                }

                    .site-footer .site-footer-content .link-content .member-support-column .member-support-phone, .site-footer .site-footer-content .link-content .demo-column .member-support-phone {
                        white-space: nowrap;
                    }

                    .site-footer .site-footer-content .link-content .member-support-column .member-support-email, .site-footer .site-footer-content .link-content .demo-column .member-support-email {
                        word-break: normal;
                    }

            .site-footer .site-footer-content .demo-login-content {
                min-height: 110px;
            }

        .site-footer *::-ms-backdrop,
        .site-footer .site-footer-content {
            margin: auto;
        }


        .site-footer .disclosure-row {
            -webkit-box-orient: vertical;
            -webkit-box-direction: normal;
            -webkit-flex-direction: column;
               -moz-box-orient: vertical;
               -moz-box-direction: normal;
                -ms-flex-direction: column;
                    flex-direction: column;
        }

            .site-footer .disclosure-row .disclosure-items {
                -webkit-box-flex: 0;
                -webkit-flex-grow: 0;
                   -moz-box-flex: 0;
                    -ms-flex-positive: 0;
                        flex-grow: 0;
                -webkit-flex-shrink: 0;
                    -ms-flex-negative: 0;
                        flex-shrink: 0;
                -webkit-flex-basis: calc(99.9% * 1);
                    -ms-flex-preferred-size: calc(99.9% * 1);
                        flex-basis: calc(99.9% * 1);
                max-width: calc(99.9% * 1);
                width: calc(99.9% * 1);
                padding-bottom: 0;
            }

            .site-footer .disclosure-row .disclosure-items:nth-child(1n) {
                margin-right: 0;
                margin-left: 0;
            }

            .site-footer .disclosure-row .disclosure-items:last-child {
                margin-right: 0;
            }

        .site-footer *::-ms-backdrop,
        .site-footer .disclosure-row {
            display: block;
        }

        .site-footer *::-ms-backdrop,
        .site-footer .disclosure-row .disclosure-items:last-of-type {
            padding-bottom: 32px;
        }

        .site-footer *::-ms-backdrop,
        .site-footer .site-footer-content .demo-login-content.demo-login-content {
            margin: auto;
        }

        .site-footer .privacy-terms-row ul {
            margin-bottom: 0;
        }

            .site-footer .privacy-terms-row ul li {
                margin-bottom: 8px;
            }
            .site-footer .privacy-terms-row .privacy-logged-in .privacy-section,
            .site-footer .privacy-terms-row .privacy-logged-in .terms-section {
                margin-left: 15%;
            }
}
@media (max-width: 30em) {
        .site-footer .privacy-terms-row .privacy-logged-in .privacy-section,
        .site-footer .privacy-terms-row .privacy-logged-in .terms-section {
            margin-left: 5%;
        }

            .site-footer .privacy-terms-row .privacy-logged-in .privacy-section *, .site-footer .privacy-terms-row .privacy-logged-in .terms-section * {
                width: 176px;
            }
}
@media (max-width: 23em) {
        .site-footer .site-footer-content .social-content {
            height: 48px;
            margin: 0 auto 32px;
        }

        .site-footer .site-footer-content .social-content,
        .site-footer .site-footer-content .link-content {
            width: 288px;
            -webkit-box-orient: vertical;
            -webkit-box-direction: normal;
            -webkit-flex-direction: column;
               -moz-box-orient: vertical;
               -moz-box-direction: normal;
                -ms-flex-direction: column;
                    flex-direction: column;
        }

            .site-footer .site-footer-content .social-content .static-columns, .site-footer .site-footer-content .link-content .static-columns {
                padding-top: 16px;
            }

        .site-footer .site-footer-content {

        padding: 8px;
    }
        .site-footer .privacy-terms-row .privacy-logged-in .privacy-section,
        .site-footer .privacy-terms-row .privacy-logged-in .terms-section {
            margin-left: 4%;
        }

            .site-footer .privacy-terms-row .privacy-logged-in .privacy-section *, .site-footer .privacy-terms-row .privacy-logged-in .terms-section * {
                width: 156px;
            }
}
