/**
 * CSS for laying out and displaying blocks and boxes
 */
:root {

    /* Consistent paddings */

    /* shadows */

    /* All z-index values should be listed here */

    /* widths */
}
/**
 * CSS for colors
 */
/* stylelint-disable color-no-hex */
:root {
    /* TIER ONE - DESCRIPTIVE NAMES */

    /* Default / Text color + Light background / Background color + Light text */        /* dark orange */          /* brand blue */           /* red */        /* purple */         /* dark green */          /* gray */            /* white */        /* yellow */

    /* Highlight / Text color + Dark background / Background color + Dark text */                                /* orange */            /* light red #f0425f */      /* light purple #ce51e1 */                                 /* bright green */                                  /* bright blue - brand color */                                /* light yellow */

    /* Alerts */

    /* Additional blues */         /* dark blue #003366 */  /* medium blue #0066cc */ /* light blue #c7e3ff */  /* light blue #e5f2ff */

    /* Additional reds */  /* light red #fbd0d7 */  /* dark red #8E0B21 */

    /* Additional greens */                                 /* dark green */ /* light green #e5fff1 */

    /* Additional purples */    /* extra light purple #f3d3f8 */       /* very light purple #f3d3f840 */

    /* Additional oranges */    /* light orange #ffe8cc */

    /* Grayscale Colors */  /* Near black */ /* Dark gray #1d2830 */  /* Formerly graphite */       /* medium gray */ /* #b1b2b4 */        /* #d8d9da */  /* Near white #f2f2f3 */

    /* TIER TWO - SEMANTIC NAMES */

    /* Product Colors */

    /* Component Colors */

    /* Partner/Refi Lender Colors */

    /* First Republic palette */    /* forest green */        /* gold hacienda */        /* tan gray concrete */      /* dark gray matterhorn */

    /* Visualization palette */

    /* Marketing Page Colors */ /* #1d2830; */ /* #005cb9; *//* #0f1519; */ /* #1d2830; */ /* #5a666e; *//* #8a8c8f; */ /* #b1b2b4; */ /* #d7d8d9; *//* #f2f2f3; */ /* #e5f2ff; */ /* #eddef0; */
}
/* this section is deprecated - prefer to use device-specific names if possible */
/* device based size names */
/* iPhone 5 width: 20em */
/* iPhone 6 width: */
/* iPhone 5 height: 35.5em */
/* iPhone 6 plus height: 46em */
/* iPad width: 48em */
/* iPad height: 64em */
.desktop {
    display: block;
}
.mobile {
    display: none;
}
@media (max-width: 30em) {
    .desktop {
        display: none;
    }

    .mobile {
        display: block;
    }
}
/**
 * CSS for text, titles, labels. word.
 */
/* Marketing Text Mixins */
/* basic setup for triangles in :before and :after pseudo-elements */
/* clears ul/ol defaults */
/* TODO Deprecated */
/* best pronounced with a texan drawl */
/* is this annoying yet? */
/* Marketing Button Mixins */
.custom-form *[disabled] {
        color: #8a8c8f;
        background-color: rgb(242, 242, 243);
        cursor: context-menu;
    }
.custom-form h4,
    .custom-form h5 {
        margin: 8px 0;
    }
.custom-form section {
        margin-bottom: 16px;
    }
.custom-form .input-row:before {
        content: '';
        display: table;
    }
.custom-form .input-row:after {
        content: '';
        display: table;
        clear: both;
    }
.custom-form .input-row select,
        .custom-form .input-row input[type="text"],
        .custom-form .input-row input[type="email"],
        .custom-form .input-row input[type="password"] {
            min-width: auto;
            width: 100%;
        }
.custom-form .input-message.submit-error {
        margin-bottom: 8px;
    }
.custom-form .radio-group {
        margin: 8px 0;
    }
.custom-form .radio-group .input-row {
            margin-left: 16px;
            margin-bottom: 4px;
        }
.custom-form .inline-input {
        display: inline-block;
        width: 80px;
        margin-left: 4px;
        margin-right: 4px;
    }
.custom-form span.label::before {
            content: '';
            margin-right: 16px;
        }
.custom-form .top-level-error-message {
        margin-bottom: 16px;
    }
.custom-form .input-message {
        font-size: 0.725rem;
        line-height: 1.5;
        font-weight: 400;
        font-family: 'Montserrat', Helvetica, sans-serif;

        display: inline-block;
        padding: 4px;
        margin-left: 4px;
    }
.custom-form .valid-message {
        color: green;
        font-weight: 600;
    }
.custom-form .invalid-message {
        color: #c8102e;
        border-bottom: 1px dotted #c8102e;
        font-weight: 600;
    }
.custom-form .error-message {
        color: #c8102e;
        border-bottom: 1px dotted #c8102e;
        font-weight: 600;
    }
.custom-form .error-message a {
            color: #c8102e;
            text-decoration: underline;
            font-weight: 700;
        }
.custom-form .error-message-details {
        font-size: 0.725rem;
        line-height: 1.5;
        font-weight: 400;
        font-family: 'Montserrat', Helvetica, sans-serif;

        color: #c8102e;
        font-weight: 600;
    }
.custom-form .warning-message {
        color: #b36200;
        border-bottom-color: #b36200;
    }
.custom-form .has-errors input,
        .custom-form .has-errors select {
            border: #c8102e 1px solid;
        }
.custom-form .custom-multiselect.has-errors input {
            border: none;
        }
.custom-form label.primary {
        font-weight: 600;
    }
.custom-form label .additional-description {
        font-weight: 400;
    }
.custom-form .input-container {
        margin-bottom: 16px;
    }
.custom-form .input-container label {
            margin-bottom: 4px;
            font-weight: 600;
        }
.custom-form .custom-submit,
    .custom-form .custom-button {
        display: inline-block;
        text-decoration: none;
        padding: 8px 16px;
        border-radius: 24px;
        cursor: pointer;
        -webkit-transition: all 0.2s ease-in;
        -o-transition: all 0.2s ease-in;
        transition: all 0.2s ease-in;
        text-align: center;
        display: inline-block;
        text-decoration: none;
        padding: 8px 16px;
        border-radius: 24px;
        cursor: pointer;
        transition: all 0.2s ease-in;
        text-align: center;
        border: 2px solid #005cb9;
        color: white;
        background-color: #005cb9;
    }
@media (max-width: 40em) {
.custom-form .custom-submit,
    .custom-form .custom-button {
        height: 50px;
        margin: 8px 0;
        padding: 16px 16px 8px 16px;
        text-align: center;
        width: 100%;
    }
    }
@media (max-width: 40em) {
.custom-form .custom-submit,
    .custom-form .custom-button {
        height: 50px;
        margin: 8px 0;
        padding: 16px 16px 8px 16px;
        text-align: center;
        width: 100%;
    }
    }
.custom-form .custom-submit:hover, .custom-form .custom-button:hover {
        background-color: rgb(0, 76, 153);
        border-color: rgb(0, 76, 153);
    }
.custom-form .custom-submit:disabled, .custom-form .custom-button:disabled {
        border-color: #8a8c8f;
        background-color: #8a8c8f;
        color: white;
        cursor: default;
    }
.custom-form .custom-button.alt {
        background-color: #e58c23;
        color: white;
        font-weight: 600;
        width: auto;
    }
