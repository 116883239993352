/**
 * CSS for colors
 */
/* stylelint-disable color-no-hex */
:root {
    /* TIER ONE - DESCRIPTIVE NAMES */

    /* Default / Text color + Light background / Background color + Light text */        /* dark orange */          /* brand blue */           /* red */        /* purple */         /* dark green */          /* gray */            /* white */        /* yellow */

    /* Highlight / Text color + Dark background / Background color + Dark text */                                /* orange */            /* light red #f0425f */      /* light purple #ce51e1 */                                 /* bright green */                                  /* bright blue - brand color */                                /* light yellow */

    /* Alerts */

    /* Additional blues */         /* dark blue #003366 */  /* medium blue #0066cc */ /* light blue #c7e3ff */  /* light blue #e5f2ff */

    /* Additional reds */  /* light red #fbd0d7 */  /* dark red #8E0B21 */

    /* Additional greens */                                 /* dark green */ /* light green #e5fff1 */

    /* Additional purples */    /* extra light purple #f3d3f8 */       /* very light purple #f3d3f840 */

    /* Additional oranges */    /* light orange #ffe8cc */

    /* Grayscale Colors */  /* Near black */ /* Dark gray #1d2830 */  /* Formerly graphite */       /* medium gray */ /* #b1b2b4 */        /* #d8d9da */  /* Near white #f2f2f3 */

    /* TIER TWO - SEMANTIC NAMES */

    /* Product Colors */

    /* Component Colors */

    /* Partner/Refi Lender Colors */

    /* First Republic palette */    /* forest green */        /* gold hacienda */        /* tan gray concrete */      /* dark gray matterhorn */

    /* Visualization palette */

    /* Marketing Page Colors */ /* #1d2830; */ /* #005cb9; *//* #0f1519; */ /* #1d2830; */ /* #5a666e; *//* #8a8c8f; */ /* #b1b2b4; */ /* #d7d8d9; *//* #f2f2f3; */ /* #e5f2ff; */ /* #eddef0; */
}
/* this section is deprecated - prefer to use device-specific names if possible */
/* device based size names */
/* iPhone 5 width: 20em */
/* iPhone 6 width: */
/* iPhone 5 height: 35.5em */
/* iPhone 6 plus height: 46em */
/* iPad width: 48em */
/* iPad height: 64em */
.desktop {
    display: block;
}
.mobile {
    display: none;
}
@media (max-width: 30em) {
    .desktop {
        display: none;
    }

    .mobile {
        display: block;
    }
}
/**
 * CSS for text, titles, labels. word.
 */
/* Marketing Text Mixins */
/**
 * CSS for laying out and displaying blocks and boxes
 */
:root {

    /* Consistent paddings */

    /* shadows */

    /* All z-index values should be listed here */

    /* widths */
}
/* Mixins to be applied to the .tooltip element of Tooltip.js */
.tooltip .tooltip-content {
        color: rgb(29, 40, 48);
    }
.tooltip .tooltip-content {
        background-color: white;
    }
.tooltip .primary-top .tooltip-content::after {
        border-top: 14px solid white;
    }
.tooltip .primary-bottom .tooltip-content::after {
        border-bottom: 14px solid white;
    }
.tooltip .primary-left .tooltip-content::after {
        border-left: 14px solid white;
    }
.tooltip .primary-right .tooltip-content::after {
        border-right: 14px solid white;
    }
.tooltip .tooltip-content {
        border: 1px solid rgb(177, 178, 180);
    }
.tooltip .primary-top .tooltip-content::before {
        border-top: solid 16px rgb(177, 178, 180);
    }
.tooltip .primary-bottom .tooltip-content::before {
        border-bottom: solid 16px rgb(177, 178, 180);
    }
.tooltip .primary-left .tooltip-content::before {
        border-left: 16px solid rgb(177, 178, 180);
    }
.tooltip .primary-right .tooltip-content::before {
        border-right: 16px solid rgb(177, 178, 180);
    }
.tooltip .icon-info-circled {
        background-color: ;
        color: rgb(0, 51, 102) white;
    }
.tooltip .tooltip-content .cancel-button-container {
            color: white;
        }
.tooltip .tooltip-content {
        -webkit-box-shadow: 1px 1px 3px 1px rgba(rgb(29, 40, 48), 0.2);
                box-shadow: 1px 1px 3px 1px rgba(rgb(29, 40, 48), 0.2);
    }
.tooltip {

    display: inline-block;
    text-decoration: none;
}
.tooltip > a {
        text-decoration: none;
    }
.tooltip .icon-info-circled {
        font-size: 0.875rem;
        line-height: 1.5;
        font-weight: 400;
        font-family: 'Montserrat', Helvetica, sans-serif;

        line-height: 1;
        vertical-align: top;
        pointer-events: all;
        cursor: pointer;
    }
.tooltip.has-label > a {
            text-decoration: underline;
        }
.tooltip.has-label .primary-right {
            position: absolute;
            right: 10px;
        }
.tooltip .tooltip-mount {
        width: 0;
        height: 0;
    }
.tooltip .tooltip-content {
        font-size: 0.725rem;
        line-height: 1.5;
        text-transform: uppercase;
        font-weight: 500;
        font-family: 'Montserrat', Helvetica, sans-serif;

        text-transform: none;
        width: 300px;
        position: absolute;
        min-width: 100px;
        min-height: 32px;
        z-index: 60;
        text-align: left;
        border-radius: 8px;
        padding: 12px;
    }
.tooltip .tooltip-content .cancel-button-container {
            position: absolute;
            right: 4px;
            top: 4px;
            font-size: 1rem;
            cursor: pointer;
            z-index: 1;
        }
.tooltip .tooltip-content::before {
            content: "";
            position: absolute;
            border: 10px solid transparent;
            margin-left: -10px;
        }
.tooltip .tooltip-content::after {
            content: "";
            position: absolute;
            border: 8px solid transparent;
            margin-left: -8px;
        }
/* adjust positioning to account for icon size */
.tooltip .primary-right .tooltip-content {
        left: 32px;
    }
.tooltip .primary-right .tooltip-content::before {
            left: -16px;
        }
.tooltip .primary-right .tooltip-content::after {
            left: -14px;
        }
.tooltip .primary-top .tooltip-content {
        bottom: 14px;
    }
.tooltip .primary-top .tooltip-content::before {
            bottom: -26px;
        }
.tooltip .primary-top .tooltip-content::after {
            bottom: -22px;
        }
.tooltip .primary-left .tooltip-content {
        right: 12px;
    }
.tooltip .primary-left .tooltip-content::before {
            right: -26px;
        }
.tooltip .primary-left .tooltip-content::after {
            right: -22px;
        }
.tooltip .primary-bottom .tooltip-content {
        top: 32px;
    }
.tooltip .primary-bottom .tooltip-content::before {
            top: -26px;
        }
.tooltip .primary-bottom .tooltip-content::after {
            top: -22px;
        }
.tooltip .secondary-top .tooltip-content {
        bottom: -30px;
    }
.tooltip .secondary-top .tooltip-content::before {
            bottom: 10px;
        }
.tooltip .secondary-top .tooltip-content::after {
            bottom: 12px;
        }
.tooltip .secondary-bottom .tooltip-content {
        top: -12px;
    }
.tooltip .secondary-bottom .tooltip-content::before {
            top: 12px;
        }
.tooltip .secondary-bottom .tooltip-content::after {
            top: 14px;
        }
.tooltip .secondary-left .tooltip-content {
        right: -30px;
    }
.tooltip .secondary-left .tooltip-content::before {
            right: 10px;
        }
.tooltip .secondary-left .tooltip-content::after {
            right: 12px;
        }
.tooltip .secondary-left .tooltip-content.adjusted-50 {
            right: -80px;
        }
.tooltip .secondary-left .tooltip-content.adjusted-50::before {
            right: 60px;
        }
.tooltip .secondary-left .tooltip-content.adjusted-50::after {
            right: 62px;
        }
.tooltip .secondary-left .tooltip-content.adjusted-100 {
            right: -130px;
        }
.tooltip .secondary-left .tooltip-content.adjusted-100::before {
            right: 110px;
        }
.tooltip .secondary-left .tooltip-content.adjusted-100::after {
            right: 112px;
        }
.tooltip .secondary-left .tooltip-content.adjusted-150 {
            right: -180px;
        }
.tooltip .secondary-left .tooltip-content.adjusted-150::before {
            right: 160px;
        }
.tooltip .secondary-left .tooltip-content.adjusted-150::after {
            right: 162px;
        }
.tooltip .secondary-left .tooltip-content.adjusted-200 {
            right: -230px;
        }
.tooltip .secondary-left .tooltip-content.adjusted-200::before {
            right: 210px;
        }
.tooltip .secondary-left .tooltip-content.adjusted-200::after {
            right: 212px;
        }
.tooltip .secondary-left .tooltip-content.adjusted-250 {
            right: -280px;
        }
.tooltip .secondary-left .tooltip-content.adjusted-250::before {
            right: 260px;
        }
.tooltip .secondary-left .tooltip-content.adjusted-250::after {
            right: 262px;
        }
.tooltip .secondary-left .tooltip-content.adjusted-300 {
            right: -330px;
        }
.tooltip .secondary-left .tooltip-content.adjusted-300::before {
            right: 310px;
        }
.tooltip .secondary-left .tooltip-content.adjusted-300::after {
            right: 312px;
        }
.tooltip .secondary-right .tooltip-content {
        left: -20px;
    }
.tooltip .secondary-right .tooltip-content::before,
        .tooltip .secondary-right .tooltip-content::after {
            left: 26px;
        }
.tooltip .secondary-right .tooltip-content.adjusted-50 {
            left: -70px;
        }
.tooltip .secondary-right .tooltip-content.adjusted-50::before,
        .tooltip .secondary-right .tooltip-content.adjusted-50::after {
            left: 76px;
        }
.tooltip .secondary-right .tooltip-content.adjusted-100 {
            left: -120px;
        }
.tooltip .secondary-right .tooltip-content.adjusted-100::before,
        .tooltip .secondary-right .tooltip-content.adjusted-100::after {
            left: 126px;
        }
.tooltip .secondary-right .tooltip-content.adjusted-150 {
            left: -170px;
        }
.tooltip .secondary-right .tooltip-content.adjusted-150::before,
        .tooltip .secondary-right .tooltip-content.adjusted-150::after {
            left: 176px;
        }
.tooltip .secondary-right .tooltip-content.adjusted-200 {
            left: -220px;
        }
.tooltip .secondary-right .tooltip-content.adjusted-200::before,
        .tooltip .secondary-right .tooltip-content.adjusted-200::after {
            left: 226px;
        }
.tooltip .secondary-right .tooltip-content.adjusted-250 {
            left: -270px;
        }
.tooltip .secondary-right .tooltip-content.adjusted-250::before,
        .tooltip .secondary-right .tooltip-content.adjusted-250::after {
            left: 276px;
        }
.tooltip .secondary-right .tooltip-content.adjusted-300 {
            left: -320px;
        }
.tooltip .secondary-right .tooltip-content.adjusted-300::before,
        .tooltip .secondary-right .tooltip-content.adjusted-300::after {
            left: 326px;
        }
