/* this section is deprecated - prefer to use device-specific names if possible */
/* device based size names */
/* iPhone 5 width: 20em */
/* iPhone 6 width: */
/* iPhone 5 height: 35.5em */
/* iPhone 6 plus height: 46em */
/* iPad width: 48em */
/* iPad height: 64em */
.desktop {
    display: block;
}
.mobile {
    display: none;
}
@media (max-width: 30em) {
    .desktop {
        display: none;
    }

    .mobile {
        display: block;
    }
}
/**
 * CSS for colors
 */
/* stylelint-disable color-no-hex */
:root {
    /* TIER ONE - DESCRIPTIVE NAMES */

    /* Default / Text color + Light background / Background color + Light text */        /* dark orange */          /* brand blue */           /* red */        /* purple */         /* dark green */          /* gray */            /* white */        /* yellow */

    /* Highlight / Text color + Dark background / Background color + Dark text */                                /* orange */            /* light red #f0425f */      /* light purple #ce51e1 */                                 /* bright green */                                  /* bright blue - brand color */                                /* light yellow */

    /* Alerts */

    /* Additional blues */         /* dark blue #003366 */  /* medium blue #0066cc */ /* light blue #c7e3ff */  /* light blue #e5f2ff */

    /* Additional reds */  /* light red #fbd0d7 */  /* dark red #8E0B21 */

    /* Additional greens */                                 /* dark green */ /* light green #e5fff1 */

    /* Additional purples */    /* extra light purple #f3d3f8 */       /* very light purple #f3d3f840 */

    /* Additional oranges */    /* light orange #ffe8cc */

    /* Grayscale Colors */  /* Near black */ /* Dark gray #1d2830 */  /* Formerly graphite */       /* medium gray */ /* #b1b2b4 */        /* #d8d9da */  /* Near white #f2f2f3 */

    /* TIER TWO - SEMANTIC NAMES */

    /* Product Colors */

    /* Component Colors */

    /* Partner/Refi Lender Colors */

    /* First Republic palette */    /* forest green */        /* gold hacienda */        /* tan gray concrete */      /* dark gray matterhorn */

    /* Visualization palette */

    /* Marketing Page Colors */ /* #1d2830; */ /* #005cb9; *//* #0f1519; */ /* #1d2830; */ /* #5a666e; *//* #8a8c8f; */ /* #b1b2b4; */ /* #d7d8d9; *//* #f2f2f3; */ /* #e5f2ff; */ /* #eddef0; */
}
/**
 * CSS for text, titles, labels. word.
 */
/* Marketing Text Mixins */
/**
 * CSS for laying out and displaying blocks and boxes
 */
:root {

    /* Consistent paddings */

    /* shadows */

    /* All z-index values should be listed here */

    /* widths */
}
/* basic setup for triangles in :before and :after pseudo-elements */
/* clears ul/ol defaults */
/* TODO Deprecated */
/* best pronounced with a texan drawl */
/* is this annoying yet? */
/* Marketing Button Mixins */
.confirmation-modal.modal-dialog {
        margin-top: 32px;
        border-radius: 4px;
        padding: 0;
    }
.confirmation-modal.modal-dialog .modal-header {
            display: -webkit-box;
            display: -webkit-flex;
            display: -moz-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-orient: horizontal;
            -webkit-box-direction: normal;
            -webkit-flex-flow: row wrap;
               -moz-box-orient: horizontal;
               -moz-box-direction: normal;
                -ms-flex-flow: row wrap;
                    flex-flow: row wrap;
            -webkit-box-align: center;
            -webkit-align-items: center;
               -moz-box-align: center;
                -ms-flex-align: center;
                    align-items: center;
            -webkit-box-pack: justify;
            -webkit-justify-content: space-between;
               -moz-box-pack: justify;
                -ms-flex-pack: justify;
                    justify-content: space-between;
            background: white;
            height: initial;
            padding: 32px 16px 0 32px;
            border-bottom: 0;
        }
.confirmation-modal.modal-dialog .modal-header .modal-close-button {
                color: #8a8c8f;
                -webkit-box-shadow: 0;
                        box-shadow: 0;
            }
.confirmation-modal.modal-dialog .modal-content {
            border-radius: 4px;
            background: white;
            padding: 0;
            overflow: visible;
        }
.confirmation-modal.modal-dialog .modal-body {
            padding: 32px;
        }
.confirmation-modal.modal-dialog .button-container {
            padding: 16px;
            display: -webkit-box;
            display: -webkit-flex;
            display: -moz-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-orient: horizontal;
            -webkit-box-direction: normal;
            -webkit-flex-flow: row wrap;
               -moz-box-orient: horizontal;
               -moz-box-direction: normal;
                -ms-flex-flow: row wrap;
                    flex-flow: row wrap;
            -webkit-box-pack: end;
            -webkit-justify-content: flex-end;
               -moz-box-pack: end;
                -ms-flex-pack: end;
                    justify-content: flex-end;
            -webkit-flex-wrap: wrap-reverse;
                -ms-flex-wrap: wrap-reverse;
                    flex-wrap: wrap-reverse;
            background-color: rgb(229, 242, 255);
            border-radius: 0 0 4px 4px;
        }
.confirmation-modal.modal-dialog .button-container a,
            .confirmation-modal.modal-dialog .button-container button {
                margin-left: 16px;
            }
@media (max-width: 40em) {
        .confirmation-modal.modal-dialog {
            margin: 8px;
            max-width: 100vw;
        }

            .confirmation-modal.modal-dialog .modal-header {
                padding: 24px;
            }

            .confirmation-modal.modal-dialog .modal-body {
                padding: 24px;
            }

            .confirmation-modal.modal-dialog .button-container {
                max-width: 100vw;
                -webkit-box-pack: center;
                -webkit-justify-content: center;
                   -moz-box-pack: center;
                    -ms-flex-pack: center;
                        justify-content: center;
                padding-bottom: 24px;
            }

                .confirmation-modal.modal-dialog .button-container a,
                .confirmation-modal.modal-dialog .button-container button {
                    margin: 8px;
                }
    }
.confirmation-modal .message {
        margin-bottom: 16px;
    }
.confirmation-modal .button-container .cancel-button {
            text-decoration: underline;
            cursor: pointer;
            padding: 0;
            margin: 8px 16px;
            background: transparent;
            border: none;
            color: rgb(29, 40, 48);
            -webkit-box-shadow: none;
                    box-shadow: none;
        }
.confirmation-modal .button-container .cancel-button:disabled {
        color: rgb(216, 217, 218);
        cursor: default;
    }
.confirmation-modal .button-container .confirm-button {
            display: inline-block;
            text-decoration: none;
            padding: 8px 16px;
            border-radius: 24px;
            cursor: pointer;
            -webkit-transition: all 0.2s ease-in;
            -o-transition: all 0.2s ease-in;
            transition: all 0.2s ease-in;
            text-align: center;
            display: inline-block;
            text-decoration: none;
            padding: 8px 16px;
            border-radius: 24px;
            cursor: pointer;
            transition: all 0.2s ease-in;
            text-align: center;
            border: 2px solid #005cb9;
            color: white;
            background-color: #005cb9;
        }
@media (max-width: 40em) {
.confirmation-modal .button-container .confirm-button {
        height: 50px;
        margin: 8px 0;
        padding: 16px 16px 8px 16px;
        text-align: center;
        width: 100%;
        }
    }
@media (max-width: 40em) {
.confirmation-modal .button-container .confirm-button {
        height: 50px;
        margin: 8px 0;
        padding: 16px 16px 8px 16px;
        text-align: center;
        width: 100%;
        }
    }
.confirmation-modal .button-container .confirm-button:hover {
        background-color: rgb(0, 76, 153);
        border-color: rgb(0, 76, 153);
    }
.confirmation-modal .button-container .confirm-button:disabled {
        border-color: #8a8c8f;
        background-color: #8a8c8f;
        color: white;
        cursor: default;
    }
@media (max-width: 40em) {
            .confirmation-modal.modal-dialog .modal-content .modal-header {
                padding: 16px 24px 8px;
            }

            .confirmation-modal.modal-dialog .modal-content .button-container {
                padding: 8px;
            }

                .confirmation-modal.modal-dialog .modal-content .button-container .cancel-button {
                    margin-top: 4px;
                    height: 35px;
                }
}
